.intro-option-title {
    border-bottom-width: 0;
    background-image: linear-gradient(#C2D730, #C2D730);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .25s ease-in-out;
}

.intro-option-container:hover > .intro-option-title {
    background-size: 100% 3px;
    background-position: 0 100%;
    font-weight: 700;
}
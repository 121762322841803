#btnSendRequestLabel {
    border-bottom-width: 0;
    background-image: linear-gradient(#343B3D, #343B3D);
    background-size: 0 3px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size .25s ease-in-out;
}

.btn:hover > #btnSendRequestLabel {
    background-size: 100% 3px;
    font-weight: 700;
}
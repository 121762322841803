@import url('https://fonts.cdnfonts.com/css/gotham');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Gotham', 'sans-serif'
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham'), url('/public/fonts/woff/gothamlight-webfont.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham'), url('/public/fonts/woff/gothambold-webfont.woff') format('woff');
}
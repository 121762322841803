.dimensions-input{
    text-align: right;
}

.dimensions-input::-webkit-outer-spin-button,
.dimensions-input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

input.dimensions-input[type=number]{
    -moz-appearance: textfield;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}